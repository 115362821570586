<!--
 * @Description: 流程审批设置
 * @Author: 琢磨先生
 * @Date: 2022-09-11 04:28:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-18 21:19:23
-->
<template>
  <el-scrollbar>
    <div class="jtk_container" ref="jsPlumbContainer">
      <div class="node_wrp">
        <div class="node" ref="node_start" shadow="never">
          <div class="node_inner start">
            <div class="name">申请人</div>
            <div class="content">
              <div
                class="content_text"
              >适用范围：{{model.is_everyone?'全员':model.applicant_list && Array.isArray(model.applicant_list) ? model.applicant_list.map(o=>o.name).join():'' }}</div>
            </div>
          </div>
        </div>
        <div class="add_box">
          <div class="btn_box">
            <el-popover placement="right" :width="200" popper-class="flow_popover">
              <template #reference>
                <el-button type="primary" circle icon="Plus"></el-button>
              </template>
              <div class="add_menus">
                <div class="menu-item approver" @click="onAddNode(0,0)">
                  <el-icon :size="30">
                    <Avatar />
                  </el-icon>
                  <div class>审批人</div>
                </div>
                <div class="menu-item notifier" @click="onAddNode(0,10)">
                  <el-icon :size="30">
                    <Promotion />
                  </el-icon>
                  <div class>抄送人</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>

      <div class="node_wrp" v-for="(item,i) in node_list" :key="item">
        <div class="node" @click="onNodeClick(i)">
          <div class="node_inner approver" v-if="item.type==0">
            <div class="name">
              {{item.name}}
              <span class="remove" @click.stop="onRemoveNode(i)">
                <el-icon>
                  <Close />
                </el-icon>
              </span>
            </div>

            <div class="content">
              <div class="content_text">
                <div
                  v-if="item.appoint_type===0"
                >{{item.emp_list.length==0?'请选择审批人':'指定人员审批：'+ item.emp_list.map(x=>x.chs_name).join()}}</div>
                <div
                  v-if="item.appoint_type===1"
                >部门负责人审批：{{dept_master_list[item.dept_master_level].label}}</div>
                <div v-if="item.appoint_type===2">
                  申请人自选
                  {{item.is_self_emp_range?'指定范围':'不限范围'}}
                  {{item.is_self_multiple?'多选':'单选'}}
                  {{item.way==0?'或签':item.way==1?'会签':'依次审批'}}
                </div>
                <div
                  v-if="item.appoint_type===3"
                >{{item.post_list.length==0?'请选择审批岗位角色':'指定岗位角色审批：'+item.post_list.map(x=>x.name).join()}}</div>
              </div>
              <el-icon>
                <ArrowRight />
              </el-icon>
            </div>
            <div class="way" v-if="item.way==0">或签</div>
            <div class="way fit" v-if="item.way==1">会签</div>
          </div>
          <div class="node_inner notify" v-if="item.type==10">
            <div class="name">
              {{item.name}}
              <span class="remove" @click.stop="onRemoveNode(i)">
                <el-icon>
                  <Close />
                </el-icon>
              </span>
            </div>
            <div class="content">
              <div class="content_text">
                <div
                  v-if="!item.notify_emp_list && !item.notify_dept_list && !item.is_self_notify"
                >请选择抄送人</div>
                <div
                  class="content_row"
                  v-if="item.notify_emp_list"
                >指定范围：{{item.notify_emp_list.map(x=>x.chs_name).join()}}</div>
                <div
                  class="content_row"
                  v-if="item.notify_dept_list"
                >部门负责人：{{item.notify_dept_list.map(x=>x.name).join()}}</div>
                <div class="content_row" v-if="item.is_self_notify">申请人自选</div>
              </div>
              <el-icon>
                <ArrowRight />
              </el-icon>
            </div>
          </div>
        </div>

        <div class="add_box">
          <div class="btn_box">
            <el-popover placement="right" :width="200" popper-class="flow_popover">
              <template #reference>
                <el-button type="primary" circle icon="Plus"></el-button>
              </template>
              <div class="add_menus">
                <div class="menu-item approver" @click="onAddNode(i+1,0)">
                  <el-icon :size="30">
                    <Avatar />
                  </el-icon>
                  <div class>审批人</div>
                </div>
                <div class="menu-item notifier" @click="onAddNode(i+1,10)">
                  <el-icon :size="30">
                    <Promotion />
                  </el-icon>
                  <div class>抄送人</div>
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>

      <div class="node_end">结束</div>
    </div>
  </el-scrollbar>

  <el-drawer
    :title="form.type==0?'审批人设置':'抄送人设置'"
    custom-class="approve_form_drawer"
    v-model="dialogVisible"
    size="700px"
    :close-on-click-modal="false"
    :append-to-body="true"
  >
    <el-form :model="form" ref="form" :rules="rules" label-position="top" v-if="form.type==0">
      <el-form-item label="节点名称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="指定方式">
        <el-radio-group v-model="form.appoint_type" @change="appointTypeChange">
          <el-radio :label="0">指定人员</el-radio>
          <el-radio :label="1">部门负责人</el-radio>
          <el-radio :label="2">申请人自选</el-radio>
          <el-radio :label="3">指定角色</el-radio>
        </el-radio-group>
        <div class="approver_detail">
          <div class="appoint" v-if="form.appoint_type===0">
            <emp-choose @success="empSelectionChange"></emp-choose>
            <el-card shadow="never" style="border:none;">
              <el-tag
                style="margin:0 10px 10px 0;"
                v-for="(item,i) in form.emp_list"
                :key="item.id"
                closable
                @close="onRemoveEmp(i)"
              >{{item.chs_name}}</el-tag>
            </el-card>
          </div>
          <div class="appoint" v-if="form.appoint_type===1">
            <div>
              <el-select v-model="form.dept_master_level" placeholder @change="deptMasterChange">
                <el-option
                  :label="item.label"
                  v-for="item in dept_master_list"
                  :key="item.value"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <el-checkbox label v-model="form.is_dept_to_up">当前层级无部门负责人时，由上一级部门负责人审批</el-checkbox>
            </div>
            <div>
              <el-checkbox
                label
                v-model="form.is_dept_no_exception"
              >此审批节点为空时直接跳过（找不到符合条件的部门负责人），不视为异常</el-checkbox>
            </div>
          </div>

          <div class="appoint" v-if="form.appoint_type===2">
            <div>
              <el-radio-group v-model="form.is_self_multiple">
                <el-radio :label="false">单选</el-radio>
                <el-radio :label="true">多选</el-radio>
              </el-radio-group>
            </div>
            <div>
              <el-radio-group v-model="form.is_self_emp_range">
                <el-radio :label="false">不限范围</el-radio>
                <el-radio :label="true">指定范围</el-radio>
              </el-radio-group>
            </div>
            <div v-if="form.appoint_type===2 && form.is_self_emp_range">
              <emp-choose @success="selfEmpChange"></emp-choose>
              <el-card shadow="never" style="border:none;">
                <el-tag
                  type="info"
                  style="margin:0 10px 10px 0;"
                  v-for="(item,i) in form.self_emp_list"
                  :key="item.id"
                  closable
                  @close="onSelfEmpRemove(i)"
                >{{item.chs_name}}</el-tag>
              </el-card>
            </div>
          </div>

          <div class="appoint" v-if="form.appoint_type===3">
            <post-choose @success="postSelectionChange"></post-choose>

            <el-card shadow="never" style="border:none;">
              <el-tag
                style="margin:0 10px 10px 0;"
                v-for="(item,i) in form.post_list"
                :key="item.id"
                closable
                @close="onPostRemove(i)"
              >{{item.name}}</el-tag>
            </el-card>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label="多人审批时采用的审批方式"
        prop="way"
        v-if="form.appoint_type!=2 || form.is_self_multiple"
      >
        <el-radio-group v-model="form.way" class="group_column">
          <el-radio :label="0">或签(一名审批人同意或拒绝即可)</el-radio>
          <el-radio :label="1">会签(须所有审批人同意)</el-radio>
          <el-radio :label="2" v-if="form.appoint_type===2">依次审批（按顺序依次审批）</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-form :model="form" ref="form" :rules="rules" label-position="top" v-if="form.type==10">
      <el-form-item label="指定范围">
        <div style="width:100%;">
          <emp-choose @success="notifyEmpChange"></emp-choose>
        </div>
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in form.notify_emp_list"
          :key="item.id"
          closable
          @close="onNotifyEmpRemove(i)"
        >{{item.chs_name}}</el-tag>
      </el-form-item>
      <el-form-item label="部门负责人">
        <div style="width:100%;">
          <dept-choose @success="notifyDeptChange"></dept-choose>
        </div>
        <el-tag
          type="info"
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in form.notify_dept_list"
          :key="item.id"
          closable
          @close="onNotifyDeptRemove(i)"
        >{{item.name}}</el-tag>
      </el-form-item>

      <el-form-item label="申请人自选">
        <el-checkbox label v-model="form.is_self_notify">申请人自行选择抄送人</el-checkbox>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </template>
  </el-drawer>
</template>

<script>
import EmpChoose from "@/views/components/emp_choose.vue";
import PostChoose from "@/views/components/post_choose.vue";
import DeptChoose from "@/views/components/dept_choose.vue";

export default {
  components: {
    EmpChoose,
    PostChoose,
    DeptChoose,
  },
  data() {
    return {
      dialogVisible: false,
      //步骤节点列表
      node_list: [],
      form: {},
      rules: {
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      //发起人自定义
      is_custom: false,
      //选择的审核人员
      emp_list: [],
      cbkIndex: 0,
      //部门负责人
      dept_master_list: [
        { label: "直接部门负责人", value: 0 },
        { label: "第二级部门负责人", value: 1 },
        { label: "第三级部门负责人", value: 2 },
        { label: "第四级部门负责人", value: 3 },
        { label: "第五级部门负责人", value: 4 },
        { label: "第六级部门负责人", value: 5 },
        { label: "第七级部门负责人", value: 6 },
        { label: "第八级部门负责人", value: 7 },
        { label: "第九级部门负责人", value: 8 },
        { label: "第十级部门负责人", value: 9 },
      ],
    };
  },
  props: ["model"],
  watch: {
    model: {
      handler() {
        if (this.model) {
          if (this.model.node_list) {
            this.node_list = this.model.node_list;
          } else {
            this.node_list = [];
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * 添加节点
     */
    onAddNode(i, t) {
      var item = {
        name: t == 0 ? "审批人" : "抄送人",
        step: 0,
        appoint_type: 0,
        dept_master_level: 0,
        way: 0,
        is_self_multiple: false,
        is_self_emp_range: false,
        emp_list: [],
        post_list: [],
        type: t,
      };
      this.node_list.splice(i, 0, item);
      this.form = item;
    },
    /**
     * 移除节点
     */
    onRemoveNode(i) {
      this.node_list.splice(i, 1);
    },

    /**
     * 节点点击
     */
    onNodeClick(i) {
      this.form = JSON.parse(JSON.stringify(this.node_list[i]));
      this.dialogVisible = true;
      this.cbkIndex = i;
    },

    /**
     * 审批人类型更改
     */
    appointTypeChange(val) {
      this.form.way = 0;
      if (val == 0) {
        this.form.name = "审批人";
      } else if (val == 1) {
        this.form.name = "直接部门负责人";
      } else if (val == 2) {
        this.form.name = "发起人自选";
        this.form.way = 2;
      } else if (val == 3) {
        this.form.name = "指定岗位角色";
      }
    },

    /**
     * 选择人员更改
     */
    empSelectionChange(list) {
      if (!Array.isArray(this.form.emp_list)) {
        this.form.emp_list = [];
      }
      list.forEach((item) => {
        if (!this.form.emp_list.find((o) => o.id == item.id)) {
          this.form.emp_list.push({
            id: item.id,
            chs_name: item.chs_name,
          });
        }
      });
      // this.jsPlumbReDraw();
    },

    /**
     * 移除节点人员
     */
    onRemoveEmp(i) {
      this.form.emp_list.splice(i, 1);
      // this.jsPlumbReDraw();
    },
    /**
     * 岗位角色选择更改
     */
    postSelectionChange(list) {
      if (!Array.isArray(this.form.post_list)) {
        this.form.post_list = [];
      }
      list.forEach((item) => {
        if (!this.form.post_list.find((o) => o.id == item.id)) {
          this.form.post_list.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      // this.jsPlumbReDraw();
    },

    /**
     * 移除节点岗位
     */
    onPostRemove(i) {
      this.form.post_list.splice(i, 1);
      // this.jsPlumbReDraw();
    },

    /**
     * 发起人自选时限定人员范围
     */
    selfEmpChange(list) {
      this.form.self_emp_list = list.map((item) => {
        return {
          id: item.id,
          chs_name: item.chs_name,
        };
      });
    },
    /**
     * 移除发起人选择人员范围人员
     */
    onSelfEmpRemove(i) {
      this.form.self_emp_list.splice(i, 1);
    },

    /**
     * 部门负责人选择更改
     */
    deptMasterChange() {
      if (this.form.dept_master_level == 0) {
        this.form.name = "直接部门负责人";
      } else {
        this.form.name = `第${
          ["", "二", "三", "四", "五", "六", "七", "八", "九"][
            this.form.dept_master_level
          ]
        }级部门负责人`;
      }
    },

    /**
     * 抄送人选择更改
     */
    notifyEmpChange(list) {
      this.form.notify_emp_list = list.map((item) => {
        return {
          id: item.id,
          chs_name: item.chs_name,
        };
      });
    },
    /**
     *
     */
    onNotifyEmpRemove(i) {
      this.form.notify_emp_list.splice(i, 1);
    },

    /**
     *
     */
    notifyDeptChange(list) {
      this.form.notify_dept_list = list.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
    },

    /**
     *
     */
    onNotifyDeptRemove(i) {
      this.form.notify_dept_list.splice(i, 1);
    },

    /**
     * 确定配置节点审核信息
     */
    onConfirm() {
      this.node_list[this.cbkIndex] = this.form;
      this.dialogVisible = false;
    },

    /**
     * 表单验证，并返回 验证结果和表单数据
     */
    validate(fn) {
      fn({
        valid: true,
        is_custom: this.is_custom,
        node_list: this.node_list,
      });
    },
  },
};
</script>

<style scoped >
/* .row_body {
  height: 100%;
  flex-grow: 1;
}
.left,
.right {
  height: 100%;
} */

.jtk_container {
  min-height: 60vh;
  position: relative;
  background: radial-gradient(circle at 1px 1px, #999 2px, transparent 0);
  background-size: 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 节点 */
.jtk_container .node_wrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 添加按钮 */
.jtk_container .node_wrp .add_box {
  height: 100px;
  display: flex;
  position: relative;
  width: 240px;
  align-items: center;
  justify-content: center;
}
.node_wrp .add_box::before {
  content: "";
  display: block;
  position: absolute;
  background: #a8abb2;
  width: 3px;
  height: 100%;
}
.node_wrp .add_box::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -8px;
  width: 0;
  height: 0;
  border: 8px solid #a8abb2;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.node_wrp .btn_box {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* 按钮盒子 */
.flow_popover {
  padding: 0 !important;
}
.flow_popover .add_menus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.flow_popover .menu-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.flow_popover .approver {
  color: #e6a23c;
}
.flow_popover .notifier {
  color: #95d475;
}

/* 节点 */
.node_wrp .node {
  width: 280px;
  background: white;
  border-radius: 5px;
  box-shadow: var(--el-box-shadow-light);
  cursor: pointer;
  overflow: hidden;
  /* min-height: 100px; */
  position: relative;
}
.node_wrp .node:hover {
  box-shadow: var(--el-box-shadow);
}

.node_wrp .node .name {
  padding-left: 10px;
  height: 30px;
  display: flex;
  align-items: center;
  position: relative;
}

/* 申请人 */
.node_wrp .node .start .name {
  color: #409eff;
  background: #d9ecff;
}
.node_wrp .node .start {
  border-top: 4px solid #409eff;
}
/* 审批人 */
.node_wrp .node .approver {
  border-top: 4px solid #eebe77;
}

.node_wrp .node .approver .name {
  color: #e6a23c;
  background: #faecd8;
}
/* 抄送人 */
.node_wrp .node .notify {
  border-top: 4px solid #67c23a;
}

.node_wrp .node .notify .name {
  color: #67c23a;
  background: #e1f3d8;
}

/* 删除按钮 */
.node_wrp .node .remove {
  position: absolute;
  right: 0px;
  width: 30px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #f56c6c;
  transition: all 0.3s;
}
.node_wrp .node .remove:hover {
  transform: scale(1.4);
}

/* 结束 */
.node_end {
  background: white;
  height: 60px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  box-shadow: var(--el-box-shadow-light);
}

/* 或签、会签标签 */
.node .way {
  background: #409eff;
  color: white;
  padding: 2px 10px;
  font-size: 12px;
  display: inline-block;
  border-top-right-radius: 5px;
}
.node .way.fit {
  background: #f56c6c;
}

/* 审批配置描述内容 */
.node .node_inner .content {
  padding: 10px;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.node .node_inner .content .content_text {
  flex-grow: 1;
  width: 1px;
  margin-right: 10px;
}
.node .node_inner .content .content_row {
  margin-bottom: 10px;
}
.node .node_inner .content .content_row:last-child {
  margin-bottom: 0px;
}

/* 审批指定明细 */
.approver_detail {
  width: 100%;
  /* margin-left: 20px; */
  background: #f4f8fd;
  border-radius: 5px;
  padding: 10px;
  /* box-shadow: 0 -2px 8px #ccc; */
}

.group_column {
  display: block !important;
}
.group_column .el-radio {
  display: block !important;
}
</style>


 