<!--
 * @Description: 选择部门
 * @Author: 琢磨先生
 * @Date: 2022-09-17 12:07:41
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-09-18 16:52:15
-->
<template>
  <el-button type="primary" link :icon="`${icon?icon:'Plus'}`" @click="onOpen">{{text?text:'添加部门'}}</el-button>
  <el-dialog
    title="选择部门"
    v-model="visibleDialog"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :append-to-body="true"
  >
    <div style="height:40vh;">
      <el-scrollbar>
        <el-tree
          ref="deptTree"
          :data="dept_list"
          node-key="id"
          :expand-on-click-node="false"
          highlight-current
          show-checkbox
          check-strictly
          :props="{ children: 'children', label: 'name' }"
          default-expand-all
          @check-change="deptCheckChange"
        />
      </el-scrollbar>
    </div>
    <div style="margin-top:20px;">
      <el-alert type="info" :closable="false" title="已选择部门">
        <el-tag
          style="margin:0 10px 10px 0;"
          v-for="(item,i) in selection_list"
          :key="item.id"
          closable
          @close="onRemove(i)"
        >{{item.name}}</el-tag>
      </el-alert>
    </div>
    <template #footer>
      <el-button type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dept_api from "@/http/dept_api";

export default {
  data() {
    return {
      visibleDialog: false,
      //岗位列表
      dept_list: [],
      selection_list: [],
    };
  },
  props: ["single", "icon", "text"],
  emits: ["success"],
  created() {},
  methods: {
    onOpen() {
      this.visibleDialog = true;
      this.loadData();
    },
    /**
     * 加载岗位数据
     */
    loadData() {
      dept_api.get_dept().then((res) => {
        if (res.code == 0) {
          this.dept_list = res.data; 
        }
      });
    },
        /**
     * 部门选择
     */
    deptCheckChange() {
      var nodes = this.$refs.deptTree.getCheckedNodes();
      this.selection_list = nodes;
    },
    /**
     * 移除选择
     */
    onRemove(i) {
      var node = this.selection_list.splice(i, 1)[0];
      this.$refs.table.toggleRowSelection(node, false);
    },
    /**
     * 确定选择
     */
    onConfirm() {
      if (this.selection_list.length > 0) {
        var list = this.selection_list.map((x) => Object.assign({}, x));
        this.$emit("success", list);
        this.visibleDialog = false;
      }
    },
  },
};
</script>

<style   scoped>
</style>